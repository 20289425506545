$dir: ltr !default;

// Default $dir if not valid
@if $dir !=ltr and $dir !=rtl {
  $dir: ltr;
}

@function ltr($if, $else: null) {
  @if $dir !=rtl {
    @return $if;
  }

  @else {
    @return $else;
  }
}

@function rtl($if, $else: null) {
  @return ltr($else, $if);
}

$left: ltr(left, right);
$right: ltr(right, left);

@function dir-values($values) {
  @if $dir==rtl and length($values)==4 {
    // Reorder right and left positions in list
    @return nth($values, 1) nth($values, 4) nth($values, 3) nth($values, 2);
  }

  @else {
    @return $values;
  }
}

@mixin ltr {
  @if $dir !=rtl {
    @content;
  }
}

@mixin rtl {
  @if $dir==rtl {
    @content;
  }
}
